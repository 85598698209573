<template>
  <div class="login-container">
    
    <el-form
      v-if="!$states.start || $user.id"
      class="login-form-loader"
      v-loading="!$states.start || $user.id"
      element-loading-background="#2d3a4b"
    >
    </el-form>
    
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      :class="$states.start && !$user.id ? 'login-form-show' : ''"
      autocomplete="on"
      label-position="left"
      :show-message="true"
    >

      <div class="title-container">
        <h3 class="title">Digitender</h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <span class="dicon icofont-user"></span>
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="Логин / E-mail"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>

      <el-tooltip v-model="capsTooltip" placement="right" manual>
        <el-form-item prop="password">
          <span class="svg-container">
            <span class="dicon icofont-lock"></span>
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="Пароль"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <span class="dicon" :class="passwordType === 'password' ? 'icofont-eye-blocked' : 'icofont-eye'"></span>
          </span>
        </el-form-item>
      </el-tooltip>

      <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">Войти</el-button>

    </el-form>

  </div>
</template>

<script>
import { Notification } from 'element-ui';

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 4 && false) {
        callback(new Error(''))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4 && false) {
        callback(new Error('The password can not be less than 6 digits'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },
  

  mounted() {
    if (this.loginForm.username === '') {
      //this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      //this.$refs.password.focus()
    }
  },

  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.loading = true;
      this.$states.start = false;
      this.$request({
        path: '/login',
        data: {
          account: this.loginForm.username,
          password: this.loginForm.password,
        },
        call: [ this.login_complete, this ],
        cache: false,
      });
    },

    login_complete(status, data) {
      this.loading = false;
      if(status == 200) {
        this.$communication([ 'socket' ]);
        this.$supplement([ 'cards' ], this.start);
      }
      else {
        //this.error=data._alert
        Notification({
          title: data._alert,
          message: '',
          customClass: 'd-login-error',
          //duration: 0,
        });
        this.$states.start = true;
      }
    },

    start() {
      this.$states.start = true;
    },
    
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss">

.el-notification.d-login-error {
  background-color: #F56C6C;
  border: 0;
}

.d-login-error .el-notification__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.d-login-error .el-notification__title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.d-login-error .el-notification__closeBtn {
  position: static;
  margin-top: 2px;
  color: rgba(255, 255, 255, .6);
}

/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>

.dicon {
  font-size: 18px;
}

$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  position: absolute;

  .login-form-loader {
    position: absolute;
    width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
  }

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  
  .login-form.login-form-show {
    opacity: 1;
  }
  

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }

}
</style>
